export const GET_TRAININGS = gql`
  query getTrainings {
    trainings {
      items {
        trainingId
        name
        description
        duration
        provider
        createdAt
        updatedAt
        trainingType
        language
      }
      totalCount
    }
  }
`

export const GET_COURSES = gql`
  query getCourses {
    courses {
      items {
        courseId
        name
        duration
        description
        providerId
        provider
        createdAt
        updatedAt
        trainings {
          courseId
          training {
            name
            duration
          }
          trainingId
        }
        company {
          companyId
        }
      }
      totalCount
    }
  }
`

export const GET_TRAINING = gql`
  query getTraining($trainingId: String!) {
    training(trainingId: $trainingId) {
      trainingId
      name
      duration
      provider
      createdAt
      updatedAt
    }
  }
`

export const GET_APPLICANT_TRAININGS = gql`
  query getApplicantTrainings($applicantId: String!) {
    applicantTrainings(applicantId: $applicantId) {
      items {
        applicantTrainingId
        trainingId
        training {
          trainingId
          name
          duration
          provider
          createdAt
          updatedAt
        }
        applicantId
        applicant {
          applicantId
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        applicantCourses {
          applicantCourseId
          applicantTrainingId
          applicantCourse {
            course {
              name
            }
          }
        }
        employee {
          employeeId
          firstName
          lastName
        }
        status
        startDate
        endDate
        deadline
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`

export const ASSIGN_TRAINING = gql`
  mutation assignTraining($applicantId: String!, $trainingId: String!) {
    assignTraining(applicantId: $applicantId, trainingId: $trainingId) {
      applicantCourse {
        applicantCourseId
      }
      applicantTraining {
        applicantTrainingId
      }
    }
  }
`

export const ASSIGN_TRAININGS = gql`
  mutation assignTrainings(
    $applicantId: String!
    $trainingIds: [String]
    $courseIds: [String]
    $deadline: DateTime
  ) {
    assignTrainings(
      applicantId: $applicantId
      trainingIds: $trainingIds
      courseIds: $courseIds
      deadline: $deadline
    ) {
      applicantTrainingsIds
      applicantCoursesIds
    }
  }
`

export const GET_TRAINING_AUDIT_TRAIL_LOGS = gql`
  query getTrainingAuditTrailLogs(
    $applicantId: String
    $applicantTrainingId: String
    $pageInfo: PageInfoInput
  ) {
    getTrainingAuditTrailLogs(
      applicantId: $applicantId
      applicantTrainingId: $applicantTrainingId
      pageInfo: $pageInfo
    ) {
      items {
        auditTrailId
        type
        title
        message
        createdAt
        updatedAt
        employeeId
        employee {
          employeeId
          firstName
          lastName
          email
          phone
          createdAt
          updatedAt
        }
        applicantTrainingId
        applicantTraining {
          applicantTrainingId
          trainingId
          training {
            trainingId
            name
            duration
            provider
            createdAt
            updatedAt
          }
          applicantId
          applicant {
            applicantId
            firstName
            lastName
            email
            phone
            createdAt
            updatedAt
          }
          status
          startDate
          endDate
          deadline
          createdAt
          updatedAt
        }
      }
      totalCount
    }
  }
`
